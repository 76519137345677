import { DEFAULT_THEME, PINIA_THEME_KEY } from '@/assets/ts/constant';
import { useDark, useToggle } from '@vueuse/core';
import { defineStore } from 'pinia';

export const useThemeStore = defineStore(PINIA_THEME_KEY, {
    state: () => ({
        theme: DEFAULT_THEME, // 设置默认主题
    }),
    persist: true,
    actions: {
        switchTheme(theme: string) {
            this.theme = theme
            const isDark = useDark()
            if ((theme === 'dark' && isDark.value) || (theme === 'light' && !isDark.value)) {
                return
            }
            const toggleDark = useToggle(isDark)
            toggleDark()
        }
    },
});