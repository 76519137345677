export const routes = [
  {
    path: '/',
    name: '/',
    component: () => import('/builds/zws/web/iot-web-bus/web_app/src/pages/index.vue'),
    /* no children */
  },
  {
    path: '/AuthManagement',
    /* internal name: '/AuthManagement' */
    /* no component */
    children: [
      {
        path: 'PasswordRecovery',
        name: '/AuthManagement/PasswordRecovery',
        component: () => import('/builds/zws/web/iot-web-bus/web_app/src/pages/AuthManagement/PasswordRecovery.vue'),
        /* no children */
      },
      {
        path: 'Register',
        name: '/AuthManagement/Register',
        component: () => import('/builds/zws/web/iot-web-bus/web_app/src/pages/AuthManagement/Register.vue'),
        /* no children */
      }
    ],
  },
  {
    path: '/Container',
    name: '/Container',
    component: () => import('/builds/zws/web/iot-web-bus/web_app/src/pages/Container.vue'),
    children: [
      {
        path: 'AlertManagement',
        /* internal name: '/Container/AlertManagement' */
        /* no component */
        children: [
          {
            path: 'AlertList',
            name: '/Container/AlertManagement/AlertList',
            component: () => import('/builds/zws/web/iot-web-bus/web_app/src/pages/Container/AlertManagement/AlertList.vue'),
            /* no children */
          },
          {
            path: 'AlertRule',
            name: '/Container/AlertManagement/AlertRule',
            component: () => import('/builds/zws/web/iot-web-bus/web_app/src/pages/Container/AlertManagement/AlertRule.vue'),
            /* no children */
          }
        ],
      },
      {
        path: 'BusList',
        /* internal name: '/Container/BusList' */
        /* no component */
        children: [
          {
            path: '',
            name: '/Container/BusList/',
            component: () => import('/builds/zws/web/iot-web-bus/web_app/src/pages/Container/BusList/index.vue'),
            /* no children */
          },
          {
            path: 'BusDetail',
            name: '/Container/BusList/BusDetail',
            component: () => import('/builds/zws/web/iot-web-bus/web_app/src/pages/Container/BusList/BusDetail.vue'),
            /* no children */
          }
        ],
      },
      {
        path: 'DeviceManagement',
        /* internal name: '/Container/DeviceManagement' */
        /* no component */
        children: [
          {
            path: 'DeviceList',
            /* internal name: '/Container/DeviceManagement/DeviceList' */
            /* no component */
            children: [
              {
                path: '',
                name: '/Container/DeviceManagement/DeviceList/',
                component: () => import('/builds/zws/web/iot-web-bus/web_app/src/pages/Container/DeviceManagement/DeviceList/index.vue'),
                /* no children */
              },
              {
                path: 'DeviceDetail',
                name: '/Container/DeviceManagement/DeviceList/DeviceDetail',
                component: () => import('/builds/zws/web/iot-web-bus/web_app/src/pages/Container/DeviceManagement/DeviceList/DeviceDetail.vue'),
                /* no children */
              }
            ],
          }
        ],
      },
      {
        path: 'StatementManagement',
        /* internal name: '/Container/StatementManagement' */
        /* no component */
        children: [
          {
            path: 'EnergyStatistics',
            name: '/Container/StatementManagement/EnergyStatistics',
            component: () => import('/builds/zws/web/iot-web-bus/web_app/src/pages/Container/StatementManagement/EnergyStatistics.vue'),
            /* no children */
          },
          {
            path: 'MileageStatistics',
            name: '/Container/StatementManagement/MileageStatistics',
            component: () => import('/builds/zws/web/iot-web-bus/web_app/src/pages/Container/StatementManagement/MileageStatistics.vue'),
            /* no children */
          }
        ],
      },
      {
        path: 'SystemManagement',
        /* internal name: '/Container/SystemManagement' */
        /* no component */
        children: [
          {
            path: 'MenuManagement',
            name: '/Container/SystemManagement/MenuManagement',
            component: () => import('/builds/zws/web/iot-web-bus/web_app/src/pages/Container/SystemManagement/MenuManagement.vue'),
            /* no children */
          },
          {
            path: 'SystemLog',
            name: '/Container/SystemManagement/SystemLog',
            component: () => import('/builds/zws/web/iot-web-bus/web_app/src/pages/Container/SystemManagement/SystemLog.vue'),
            /* no children */
          },
          {
            path: 'SystemSetting',
            name: '/Container/SystemManagement/SystemSetting',
            component: () => import('/builds/zws/web/iot-web-bus/web_app/src/pages/Container/SystemManagement/SystemSetting.vue'),
            /* no children */
          },
          {
            path: 'UserCenter',
            name: '/Container/SystemManagement/UserCenter',
            component: () => import('/builds/zws/web/iot-web-bus/web_app/src/pages/Container/SystemManagement/UserCenter.vue'),
            /* no children */
          }
        ],
      },
      {
        path: 'UserManagement',
        /* internal name: '/Container/UserManagement' */
        /* no component */
        children: [
          {
            path: 'RoleManagement',
            name: '/Container/UserManagement/RoleManagement',
            component: () => import('/builds/zws/web/iot-web-bus/web_app/src/pages/Container/UserManagement/RoleManagement.vue'),
            /* no children */
          },
          {
            path: 'SubuserManagement',
            name: '/Container/UserManagement/SubuserManagement',
            component: () => import('/builds/zws/web/iot-web-bus/web_app/src/pages/Container/UserManagement/SubuserManagement.vue'),
            /* no children */
          }
        ],
      }
    ],
  }
]

export function handleHotUpdate(_router) {
  if (import.meta.hot) {
    import.meta.hot.data.router = _router
  }
}

if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    if (!router) {
      import.meta.hot.invalidate('[unplugin-vue-router:HMR] Cannot replace the routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    for (const route of mod.routes) {
      router.addRoute(route)
    }
    router.replace('')
  })
}

