export default {
    vehicle: 'Vehicle',
    total: 'Total',
    energyStatistics: 'Energy Statistics',
    chooseVehicle: 'Choose Vehicle',
    chooseTemplate: 'Choose Template',
    chooseDate: 'Choose Date',
    dailyReport: 'Daily Report',
    monthlyReport: 'Monthly Report',
    annualReport: 'Annual Report',
    download: 'Download',
    statisticalTime: 'Statistical Time',
    usedEnergyConsumption: 'Used',
    tractionEnergyConsumption: 'Traction',
    idleEnergyConsumption: 'Idle',
    otherEnergyConsumption: 'Other',
    feedbackEnergyConsumption: 'Feedback',
    netEnergyConsumption: 'Net',
    energyConsumption: 'Energy Consumption',
    mileage: 'Mileage',
    mileageStatistics: 'Mileage Statistics',
    avgEnergyConsumption: 'Avg Energy',
    downloading: 'Downloading',
    initialization: 'Initialization',
    inSearch: 'In Search',
    dataOrganizationInProgress: 'Data Organization In Progress',
    generatingInProgress: 'Generating In Progress',
    complete: 'Complete',
    notSupported: 'Not Supported',
    reportDownloadErr: 'Download failed, please check the filtering options and recheck the download',
    idGetErr: 'ID retrieval exception',
    downloadSuccess: 'Download Success',
    dataHistory: 'History Data',
    dataRealTime: 'Real Time Data',
    dataList: 'Data List',
    time: 'Time',
    start: 'Start',
    pause: 'Pause',
    clear: 'Clear',
    haveDownLoading: 'There is currently data being downloaded. Do you want to abort the download task and start a new download',
    downloadFormat: 'Download Format',
    format: 'Format',
    csv: 'csv Format',
    downloadFailed: 'Download failed, please try again later!',
    busList: 'Bus List',
    largeVehicleScreen: 'Large Vehicle Screen',
    largeMonitoringScreen: 'Large Monitoring Screen',
    light: 'Light',
    dart: 'Dark',
    help: 'Help',
    appDownload: 'App Download',
    home: 'Home',
    dashboard: 'Dashboard',
    data: 'Data Management',
    realTimeData: 'Real-time Data',
    historicalData: 'Historical Data',
    user: 'User Management',
    userCenter: 'User Center',
    sysSetting: 'System Settings',
    logout: 'Log Out',
    roleManagement: 'Role Management',
    api: 'API Usage',
    search: 'Search',
    batchesDelete: 'Batch Delete',
    device: 'Device',
    devType: 'Device Type',
    status: 'Status',
    operation: 'Operation',
    No: 'No.',
    all: 'All',
    handle: 'Handle',
    unhandled: 'Unprocessed',
    handled: 'Processed',
    edit: 'Edit',
    cond: 'Condition',
    lastTime: 'Last Modified Time',
    enabled: 'Enabled',
    disabled: 'Disabled',
    pleaseChoose: 'Please Select',
    lengthOfHandleDesc: 'Please enter a description between {min} and {max} characters',
    selTime: 'Please select a start and end time',
    delSeccuss: 'Delete Successfully',
    noData: 'No Data',
    noAuth: 'No Permission',
    inputDeviceNameTip: 'Please enter the device name',
    selectDeviceTip: 'Please select a device',
    alertRule: 'Alert Rule',
    delAlertEvent: 'Delete Alert Event',
    sureDelEvent: 'Are you sure you want to delete the alert event?',
    selEvent: 'Please select an alert event first',
    eventype: 'Event Type',
    alertData: 'Alert Data',
    relateDev: 'Related Device',
    relateRule: 'Related Rule',
    handler: 'Handler',
    handleMethod: 'Handling Method',
    handleTime: 'Handling Time',
    generatedTime: 'Alert Time',
    alertEvent: 'Alert Event',
    alertAction: 'Alert Action',
    recoveryAction: 'Recovery Action',
    alertRuleDetails: 'Alert Rule Details',
    alertRuleName: 'Alert Rule Name',
    alertRuleStatus: 'Alert Rule Status',
    handleAlert: 'Handle Alert',
    alertStatus: 'Alert Status',
    handleSuccess: 'Handle Successfully',
    busDetail: 'Bus Detail',
    alertRecord: 'Alert Record',
    carName: 'Car Name',
    alarmLevel: 'Alarm level',
    tip: 'Tip',
    general: 'General',
    emergency: 'Emergency',
    alarmInformation: 'Alarm information',
    pleaseBindDev: 'Please bind the device first',
    deviceList: 'Device List',
    deviceDetail: 'Device Detail',
    systemConfiguration: "System Configuration",
    pleaseInputCarNameTip: 'Please enter the car name',
    systemLog: "System Log"
}