import { createApp } from 'vue'
import { router } from './router'
import { createPinia } from 'pinia'
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate'
import App from './App.vue'
import VueI18n from './locales/index'
import ElementPlus from 'element-plus'

import '@/assets/iconfont/iconfont.css'
import 'element-plus/dist/index.css'
import 'element-plus/theme-chalk/dark/css-vars.css'
import '@/assets/styles/dark/css-vars.css'
import '@/assets/styles/common.css'

const pinia = createPinia()
const app = createApp(App)

pinia.use(piniaPluginPersistedstate)

app.use(ElementPlus)
app.use(VueI18n)
app.use(router)
app.use(pinia)
app.mount('#app')
