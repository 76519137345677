import zh from 'element-plus/dist/locale/zh-cn.mjs'
import en from 'element-plus/dist/locale/en.mjs'
import { DEFAULT_LANGUAGE, PINIA_LANGUAGE_KEY } from '@/assets/ts/constant'

export const languages = [
    {
        name: '中文',
        value: 'zh'
    },
    {
        name: 'English',
        value: 'en'
    }
]

const eleLocaleMap: any = {
    zh,
    en
}

export const getEleLocale = (lang: string) => {
    return eleLocaleMap[lang] || zh;
}

export const getStoredLanguage = () => {
    const languageFromStorage = localStorage.getItem(PINIA_LANGUAGE_KEY);
    try {
        const parsedLanguage = languageFromStorage ? JSON.parse(languageFromStorage) : null;
        return parsedLanguage?.language ?? DEFAULT_LANGUAGE;
    } catch {
        return DEFAULT_LANGUAGE
    }
};

export const getCurrentLanguageText = () => {
    const currentLanguage = getStoredLanguage();
    return currentLanguage === "zh" ? "中文" : "English";
};

export const getCurrentLanguage = () => {
    return getStoredLanguage();
};