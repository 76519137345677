import { createI18n } from 'vue-i18n'
import zh from './lang/zh'
import en from './lang/en'
import { DEFAULT_LANGUAGE } from '@/assets/ts/constant';
const i18n = createI18n({
    legacy: false,
    locale: DEFAULT_LANGUAGE,
    messages: {
        zh: zh,
        en: en
    }
})
export default i18n;
