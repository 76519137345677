
    import { SYSTEM_NAME, SYSTEM_NAME_EN } from './src/assets/ts/constant';
    const templateOutWebConfigInfo = localStorage.getItem('templateOutWebConfigInfo');

    const outWebConfigInfo = templateOutWebConfigInfo ? JSON.parse(templateOutWebConfigInfo).outWebConfigInfo : {
      systemName: SYSTEM_NAME,
      systemNameEn: SYSTEM_NAME_EN
    }

    const href = outWebConfigInfo.systemLogo || '/goLingIcon.png';

    const templateLanguage = localStorage.getItem('templateLanguage')
    const language = templateLanguage ? JSON.parse(templateLanguage).language : outWebConfigInfo.systemLanguage
    const systemName = language === 'zh' ? outWebConfigInfo.systemName : outWebConfigInfo.systemNameEn

    document.querySelector('link[rel="icon"]').setAttribute('href', href);
    document.title = systemName;

  