<template>
    <el-config-provider :locale="currentElementPlusLanguage">
        <router-view></router-view>
    </el-config-provider>
</template>
<script setup lang="ts">
import { watch, ref } from 'vue'
import { ZElementPlusThemeTool } from '@zws/tools'
import { ElConfigProvider } from 'element-plus'
import { FetchError, FetchLocale, outWebConfigConfigInfo, setFetchLocale, setResponseIntercepter } from '@zws/axios-swagger-fetch'
import { getCurrentLanguage, getEleLocale } from './locales/helper'
import { useLanguageStore } from '@/stores/languageStore'
import { useDark } from '@vueuse/core'
import { useI18n } from 'vue-i18n'
import { PINIA_LANGUAGE_KEY, PINIA_THEME_KEY } from './assets/ts/constant'
import { useThemeStore } from './stores/themeStore'
import { useOutWebConfigInfoStore } from './stores/outWebConfigInfoStore'

const { locale } = useI18n()

console.log('V1.0.0.241018')

// #79a61a #3783FD #007BFF #00a685
ZElementPlusThemeTool.setThemeColor('#00a685')

const languageStore = useLanguageStore()
const themeStore = useThemeStore()
const outWebConfigInfoStore = useOutWebConfigInfoStore()

let currentElementPlusLanguage = ref(getEleLocale(getCurrentLanguage()))
locale.value = getCurrentLanguage()

setFetchLocale(getCurrentLanguage() as FetchLocale)

const initGlobalConfig = () => {

outWebConfigConfigInfo({
  query: {
    isDebug: 0,
    resourcePath: '/bus'
  }
}).then((res: any) => {
  const systemConfigInfo = JSON.parse(res.data.link)
  // 如果本地没有缓存语言设置，则在登录页使用admin的全局设置
  if (!localStorage.getItem(PINIA_LANGUAGE_KEY)) {
    languageStore.switchLanguage(systemConfigInfo.systemLanguage)
  }
  if (!localStorage.getItem(PINIA_THEME_KEY)) {
    themeStore.switchTheme(systemConfigInfo.systemTheme)
  }
  outWebConfigInfoStore.setOutWebConfigInfo(systemConfigInfo)

}).catch((error: FetchError) => {
  // ZMessage.error(error.err.data.message)
})
}

// 获取root配置的系统配置信息
initGlobalConfig()

const setLanguagePattern = (lang: string) => {
    currentElementPlusLanguage.value = getEleLocale(lang)
    locale.value = lang
    setFetchLocale(lang as FetchLocale)
}

watch(() => languageStore.language, (newValue) => {
    setLanguagePattern(newValue)
});

const darkThemeBackgroundColorValue = ref<string>('#1d1e1f')

watch(() => useDark(), (newValue, oldValue) => {
    changeElBgColorOverlayValue(newValue.value)
})

const changeElBgColorOverlayValue = (isDark: boolean) => {
    let backgroundColorValue = isDark ? darkThemeBackgroundColorValue.value : '#ffffff'
    document.body.style.setProperty('--el-bg-color-overlay', backgroundColorValue)
}

const successCallback = (response: any) => {
    return response;
};
const errorCallback = (error: any) => {
    if (error.response && error.response.status === 401) {
        // 当提示为401无权限时，清空token，满足API库中自动跳转至/Login时，路由守卫的逻辑处理
        localStorage.removeItem('token')
    }
    return error
};
// 设置拦截器
setResponseIntercepter(successCallback, errorCallback)

</script>

<style type="text/css">
/*
	找到html标签、body标签，和挂载的标签
	都给他们统一设置样式
*/
html,
body,
#app,
.el-container {
    font-family: "Microsoft YaHei", Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    /*设置内部填充为0，几个布局元素之间没有间距*/
    padding: 0px;
    /*外部间距也是如此设置*/
    margin: 0px;
    /*统一设置高度为100%*/
    height: 100%;
    overflow: hidden;
}

.app-menu {
    position: absolute;
    right: 80px;
    bottom: 80px;
    display: flex;
    flex-direction: row;
    border: 1px solid var(--el-color-primary);
    padding: 8px;
    border-radius: 30px;
}

.app-menu-content {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-left: 10px;
    margin-right: 10px;
}
</style>
