import { DEFAULT_LANGUAGE, PINIA_LANGUAGE_KEY } from '@/assets/ts/constant';
import { defineStore } from 'pinia';

export const useLanguageStore = defineStore(PINIA_LANGUAGE_KEY, {
    state: () => ({
        language: DEFAULT_LANGUAGE, // 设置默认语言
    }),
    persist: true,
    actions: {
        switchLanguage(lang: string) {
            this.language = lang;
        },
    },
});