
import { defineStore } from 'pinia';
import { ref } from 'vue';

export const useOutWebConfigInfoStore = defineStore("templateOutWebConfigInfo", {
    state: () => ({
        outWebConfigInfo: ref<any>(null)
    }),
    persist: true,
    actions: {
        setOutWebConfigInfo(info: any) {
            this.outWebConfigInfo = info;
        },
        clearOutWebConfigInfo() {
            this.outWebConfigInfo = null;
        }
    }
});