import { createRouter, createWebHashHistory } from 'vue-router'
import { routes, handleHotUpdate } from 'vue-router/auto-routes'

export const router = createRouter({
  history: createWebHashHistory(),
  routes,
})

// This will update routes at runtime without reloading the page
if (import.meta.hot) {
  handleHotUpdate(router)
}

router.beforeEach(async (to, from) => {
  const token = localStorage.getItem('token') !== null
  if (!token && to.path == '/Login') {
    return { name: '/' }
  } else if (token && to.name === '/') {
    return { name: '/Container/BusList/' }
  } 
})