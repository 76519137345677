export default {
    vehicle: '车辆',
    total: '综合',
    energyStatistics: '能耗统计',
    chooseVehicle: '选择车辆',
    chooseTemplate: '选择模板',
    chooseDate: '选择日期',
    dailyReport: '日报表',
    monthlyReport: '月报表',
    annualReport: '年报表',
    download: '下载',
    statisticalTime: '统计时间',
    usedEnergyConsumption: '已使用能耗',
    tractionEnergyConsumption: '牵引能耗',
    idleEnergyConsumption: '怠速能耗',
    otherEnergyConsumption: '其他能耗',
    feedbackEnergyConsumption: '回馈能耗',
    netEnergyConsumption: '净能耗',
    energyConsumption: '能耗',
    mileage: '里程',
    mileageStatistics: '里程统计',
    avgEnergyConsumption: '平均耗能',
    downloading: '正在下载',
    initialization: '初始化',
    inSearch: '查询中',
    dataOrganizationInProgress: '数据整理中',
    generatingInProgress: '生成中',
    complete: '完成',
    notSupported: '不支持该格式下载',
    reportDownloadErr: '下载失败，请核对筛选项后重新查询下载',
    idGetErr: 'ID获取异常',
    downloadSuccess: '下载成功',
    dataHistory: '历史数据',
    dataRealTime: '实时数据',
    dataList: '数据列表',
    time: '时间',
    start: '开始',
    pause: '暂停',
    clear: '清空',
    haveDownLoading: '当前已有数据在下载，是否中止该下载任务，开始新的下载',
    downloadFormat: '下载格式',
    format: '格式',
    csv: 'csv格式',
    downloadFailed: '下载失败，请稍后重试！',
    busList: '车辆列表',
    largeVehicleScreen: '车辆大屏',
    largeMonitoringScreen: "监控大屏",
    light: '明亮',
    dart: '暗黑',
    help: "帮助",
    appDownload: "App下载",
    home: '首页',
    dashboard: '数据大盘',
    data: '数据管理',
    realTimeData: '实时数据',
    historicalData: '历史数据',
    user: '用户管理',
    userCenter: '用户中心',
    sysSetting: '系统设置',
    logout: '退出登录',
    roleManagement: '角色管理',
    api: 'API使用',
    search: '搜索',
    batchesDelete: '批量删除',
    device: '设备',
    devType: '设备类型',
    status: '状态',
    operation: '操作',
    No: '序号',
    all: '全部',
    handle: '处理',
    unhandled: "未处理",
    handled: "已处理",
    edit: '编辑',
    cond: '条件',
    lastTime: '最后修改时间',
    enabled: '已启用',
    disabled: '已禁用',
    pleaseChoose: "请选择",
    lengthOfHandleDesc: '请输入{min}-{max}位的字符',
    selTime: '请先选择起止时间',
    delSeccuss: '删除成功',
    noData: "暂无数据",
    noAuth: "暂无权限",
    inputDeviceNameTip: "请输入设备名称",
    selectDeviceTip: "请选择设备",
    alertRule: '告警规则',
    delAlertEvent: '删除告警事件',
    sureDelEvent: '确定删除告警事件吗？',
    selEvent: '请先选择告警事件',
    evetType: '事件类型',
    alertData: '告警数据',
    relateDev: '关联设备',
    relateRule: '关联规则',
    handler: '处理人',
    handleMethod: '处理方式',
    handleTime: '处理时间',
    generatedTime: '告警时间',
    alertEvent: '告警事件',
    alertAction: '告警动作',
    recoveryAction: '恢复动作',
    alertRuleDetails: '告警规则详情',
    alertRuleName: '告警规则名称',
    alertRuleStatus: '告警规则状态',
    handleAlert: '告警处理',
    alertStatus: '告警状态',
    handleSuccess: '处理成功',
    busDetail: '车辆详情',
    alertRecord: '告警记录',
    carName: '车辆名称',
    alarmLevel: '告警等级',
    tip: '提示',
    general: '一般',
    emergency: '紧急',
    alarmInformation: '告警信息',
    pleaseBindDev: '请先绑定设备',
    deviceList: '设备列表',
    deviceDetail: '设备详情',
    systemConfiguration: "系统配置",
    pleaseInputCarNameTip: '请输入车辆名称',
    systemLog: "系统日志"
}